<script setup>
useHead({
  title: "Vehis.pl - atrakcyjny leasing samochodów nowych i używanych",
  meta: [
    {name: 'description', content: 'Leasing samochodów z atrakcyjnymi warunkami finansowania  u lidera w branży ✔️ Decyzja w 15 min. Sprawdź!'}
  ],
});
</script>

<template>
  <Container class="container max-w-screen-2xl mx-auto px-4 lg:px-20">

    <div class="flex flex-col lg:flex-row pb-12">
      <div class="basis-3/5 shrink-0 flex-grow-0 order-2 lg:order-1">
        <div class="text-3xl text-center lg:text-left lg:text-5xl text-vehis-red">
          <h1 class="mb-6">UBEZPIECZENIA VEHIS</h1>
        </div>

        <div class="text-xl text-left leading-normal pt-8 lg:pt-16 font-light space-y-4 pl-6">
          <div class="-ml-6 font-normal">100% zabezpieczenia Twojego samochodu</div>
          <div>
            <IconBulletGray class="mr-2" />
            Pełna polisa OC/AC/NNW z możliwością rozłożenia na raty bez prowizji
          </div>
          <div>
            <IconBulletGray class="mr-2" />
            Brak udziału własnego
          </div>
          <div>
            <IconBulletGray class="mr-2" />
            Atrakcyjne ubezpieczenie GAP
          </div>

        </div>
      </div>
      <div class="basis-2/5 shrink-0 flex-grow-0 order-1 lg:order-2 flex items-center">
        <img src="@/assets/images/statics/ubezpieczenia.png" alt="">
      </div>
    </div>

    <StaticSeparator />

    <StaticHeader>Oferujemy ubezpieczenia wyłącznie renomowanych towarzystw ubezpieczeniowych</StaticHeader>

    <div class="flex justify-center py-12">
      <div class="lg:w-1/2 xl:w-1/3 flex justify-center items-center grayscale brightness-0 gap-10 px-6">
        <div class="basis-1/4 shrink grow">
          <img src="@/assets/images/statics/insurance/ergo_hestia.png" alt="" />
        </div>
        <div class="basis-1/4 shrink grow">
          <img src="@/assets/images/statics/insurance/axa.png" alt="" />
        </div>
        <div class="basis-1/4 shrink grow">
          <img src="@/assets/images/statics/insurance/benefia.png" alt="" />
        </div>
        <div class="basis-1/4 shrink grow">
          <img src="@/assets/images/statics/insurance/pzu.png" alt="" />
        </div>
      </div>
    </div>


    <StaticSeparator class="w-1/3" />

    <div class="flex flex-col lg:flex-row gap-12 lg:gap-24 lg:mx-6 pt-10">
      <StaticArticleBox class="basis-1/2" header="Ubezpieczenie na raty"
                        preheader="Oferta specjalna"
      >
        <div class="font-light">
          W VEHIS możesz rozłożyć koszt polisy ubezpieczania komunikacyjnego i GAP na dogodne raty. Dotyczy również polis zakupionych spoza naszej oferty.
        </div>
      </StaticArticleBox>
      <StaticArticleBox class="basis-1/2" header="Dorzucamy gwarancję wysokości składki"
                        preheader="Oferta specjalna"
      >
        <div class="font-light">
          Przez cały okres finansowania Twoje składki za ubezpieczenia komunikacyjne i ubezpieczenie GAP nie ulegną zmianie bez względu na inflację, wzrost cen polis w towarzystw ubezpieczeniowych, czy ilość szkód, które naprawisz z odszkodowania.
        </div>
      </StaticArticleBox>
    </div>

    <StaticSeparator class="w-1/3" />

    <StaticHeader>
      Pakiet ubezpieczeń OC/AC/NNW
      <div class="text-xl font-light pt-3 lg:mx-20">Proponujemy Ci ubezpieczenie komunikacyjne OC/AC/NNW z niską składką, liczoną według Twoich indywidualnych zniżek lub stawki ryczałtowej (od 2,19%). Płatność za polisę rozłożymy Ci w każdym roku na
        <b>12 rat.</b>
      </div>
    </StaticHeader>

    <div class="flex flex-col lg:flex-row gap-12 xl:mx-36 my-12">
      <div class="border border-gray-400 bg-white p-6 rounded lg:min-w-0 shrink grow w-full">
        <div>
          <div class="pb-4 pt-1 pl-2">
            <IconDamage />
          </div>
          <div class="font-light">
            <b>Nie stosujemy udziału własnego</b>, więc w razie szkody nie będziesz musiał nic dopłacać do naprawy samochodu.
          </div>
        </div>
      </div>

      <div class="border border-gray-400 bg-white p-6 rounded lg:min-w-0 shrink grow w-full">
        <div>
          <div class="pb-4 pt-1 pl-2">
            <IconService />
          </div>
          <div class="font-light">
            <b>Naprawy</b> wykonywane są w autoryzowanych serwisach oraz u Partnerów Serwisowych VEHIS z użyciem
            <b>oryginalnych części</b>.
          </div>
        </div>
      </div>

      <div class="border border-gray-400 bg-white p-6 rounded lg:min-w-0 shrink grow w-full">
        <div>
          <div class="pb-4 pt-1 pl-2">
            <IconInsurance />
          </div>
          <div class="font-light">Pakiet ubezpieczeniowy VEHIS obowiązuje w całej Europie.</div>
        </div>
      </div>
    </div>

    <StaticSeparator class="w-1/3" />

    <StaticHeader>Ubezpieczenie tańsze o VAT</StaticHeader>

    <StaticTwoColumn class="lg:mx-20 mb-16" no-separator centered tight>
      <template v-slot:one>
        <img src="@/assets/images/polisa.png" alt="" />
      </template>
      <template v-slot:two>
        <div class="space-y-4">
          <div>Koszt Twojej polisy obliczymy od wartości netto samochodu.</div>
          <div>
            <b>Oznacza to, że Twoja polisa liczona od niższej wartości pojazdu, dzięki VEHIS będzie zdecydowanie tańsza. Jednocześnie nie wpłynie to na jakość i koszty naprawy pojazdu realizowane z polisy.</b>
          </div>
        </div>
      </template>
    </StaticTwoColumn>

    <StaticSeparator class="w-1/3" />

    <StaticHeader>
      Ubezpieczenie ryzyka utraty wartości samochodu (polisa GAP)
      <div class="text-base font-light pt-3 mx-10">Dopełnieniem naszego pakietu ubezpieczeniowego jest ubezpieczenie różnicy pomiędzy wartością odszkodowania z polisy komunikacyjnej a wartością samochodu z dnia zakupu (tzw.&nbsp;polisa GAP). Dzięki tej polisie w przypadku szkody całkowitej lub kradzieży
        <b>odzyskasz nawet 100% wartości</b> fakturowej samochodu z dnia jego zakupu.
      </div>
    </StaticHeader>

    <div class="flex flex-col justify-center">
      <div class="text-center">Zobacz, jak działa pakiet ubezpieczeń VEHIS w całym okresie finansowania:</div>
      <div class="flex justify-center w-full py-12">
        <img src="@/assets/images/statics/ubezpieczenie_2.jpg" alt="" />
      </div>
    </div>
  </Container>
</template>
